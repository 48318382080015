import { FC, ChangeEvent } from 'react';
import { FormControlLabel } from '@mui/material';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Select, MenuItem, CircularProgress, Button, TextField, Checkbox } from '@material-ui/core';

import LineChart from 'components/line-chart';
import HtmlTable from 'components/html-table';
import ServerSidePagination from 'components/pagination/server-side-pagination';

import './styles.scss';

interface UsersTableComponentProps {
  packagesList: any[];
  tableColumns: any;
  tableData: any[];
  loading: boolean;
  toDate: any;
  fromDate: any;
  setToDate: (arg: any) => void;
  setFromDate: (arg: any) => void;
  setName: (arg: string) => void;
  setEmail: (arg: string) => void;
  setDayCount: (arg: string) => void;
  lineChartArr: { name: string; users: number }[];
  handleFilter: () => Promise<void>;
  getChartData: () => Promise<void>;
  setUserPackage: (arg: string) => void;
  userPackage: string | number;
  getData: () => Promise<void>;
  count: number;
  handlePageNumberChange: (arg: number) => void;
  scheduledToBeDeleted: boolean;
  setScheduledToBeDeleted: (arg: boolean) => void;
  anyPaid: boolean;
  setAnyPaid: (arg: boolean) => void;
  impactIds: boolean;
  setImpactIds: (arg: boolean) => void;
  generateCSVOrDrive: (arg: boolean) => Promise<void>;
  CSVUrl: string;
  setCSVUrl: (arg: string) => void;
}

const UsersTableComponent: FC<UsersTableComponentProps> = ({
  getData,
  setFromDate,
  setToDate,
  scheduledToBeDeleted,
  setScheduledToBeDeleted,
  anyPaid,
  setAnyPaid,
  impactIds,
  setImpactIds,
  toDate,
  fromDate,
  tableColumns,
  tableData,
  loading,
  setEmail,
  setName,
  handleFilter,
  lineChartArr,
  setDayCount,
  getChartData,
  packagesList,
  setUserPackage,
  userPackage,
  count,
  handlePageNumberChange,
  generateCSVOrDrive,
  CSVUrl,
  setCSVUrl,
}) => {
  const handleChange = (event: any) => setUserPackage(event.target.value);

  const handleFromDate = (date: Date) =>
    date ? setFromDate(moment(date).format('YYYY-MM-DDT00:00:00.000')) : setFromDate(null);

  const handleToDate = (date: Date) =>
    date ? setToDate(moment(date).format('YYYY-MM-DDT23:59:59.999')) : setToDate(null);

  return (
    <div style={{ margin: '0' }} className="p-4">
      <div className="row my-4">
        <div className="col-12 col-lg-9">
          <LineChart data={lineChartArr} dataKey="users" setDayCount={setDayCount} getChartData={getChartData} />
        </div>

        <div className="col-12 col-lg-3 d-flex flex-column">
          <TextField
            onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            label="Name"
            placeholder="Name"
            variant="standard"
          />
          <TextField
            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            label="Email"
            placeholder="Email"
            variant="standard"
          />
          <Select className="mt-3" label="Package" placeholder="Package" value={userPackage} onChange={handleChange}>
            <MenuItem value="All">all</MenuItem>
            {packagesList.length &&
              packagesList.map((item: any) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}{item.price === 14 || item.price === 9 ? ` - $${item.price}` : ''}
                  </MenuItem>
                );
              })}
          </Select>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={scheduledToBeDeleted}
                  onChange={() => setScheduledToBeDeleted(!scheduledToBeDeleted)}
                  color="primary"
                />
              }
              label="Scheduled to be deleted"
            />
            <FormControlLabel
              control={<Checkbox checked={impactIds} onChange={() => setImpactIds(!impactIds)} color="primary" />}
              label="Impact IDs"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={anyPaid}
                  onChange={() => setAnyPaid(!anyPaid)}
                  color="primary"
                />
              }
              label="Any Paid"
            />
          </div>
          <div className="mt-3">
            <label>From date</label>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={fromDate ? new Date(fromDate) : null}
              onChange={handleFromDate}
              showMonthDropdown
              placeholderText="Select a date"
            />
          </div>
          <div>
            <label>To date</label>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={toDate ? new Date(toDate) : null}
              onChange={handleToDate}
              showMonthDropdown
              placeholderText="Select a date"
              minDate={fromDate && new Date(fromDate)}
            />
          </div>
          <Button onClick={handleFilter} color="primary" className="mt-4" variant="contained">
            Filter
          </Button>

          <div className="mt-5 d-flex align-items-center justify-content-between">
            <span className="total-count-wrapper">
              <pre>Total count: {count}</pre>
            </span>

            <Button onClick={getData} color="primary" variant="contained">
              Reload
            </Button>
          </div>

          <div className="mt-3 csv-drive-btns-wrapper">
            <Button onClick={() => generateCSVOrDrive(true)} color="primary" variant="contained">
              Save to Drive
            </Button>
            <Button onClick={() => generateCSVOrDrive(false)} color="primary" variant="contained">
              Get CSV link
            </Button>
          </div>
          {CSVUrl && (
            <div className="mt-3 d-flex align-items-center justify-content-end">
              <a onClick={() => setCSVUrl('')} href={CSVUrl}>
                Click here for CSV link
              </a>
            </div>
          )}
        </div>
      </div>

      <ServerSidePagination data={tableData} total={count} onPageNum={handlePageNumberChange}>
        {loading ? (
          <div className="d-flex justify-content-center my-5">
            <CircularProgress />
          </div>
        ) : (
          <HtmlTable data={tableData} columns={tableColumns} tableType="users" userPackage={packagesList} />
        )}
      </ServerSidePagination>
      {/* {loading ? (
        <div className="d-flex justify-content-center mt-5">
          <CircularProgress />
        </div>
      ) : (
        <ServerSidePagination data={tableData} total={count} onPageNum={handlePageNumberChange}>
          <HtmlTable data={tableData} columns={tableColumns} tableType="users" />
        </ServerSidePagination>
        // <Material data={tableData} columns={tableColumns} loading={loading} />
      )} */}
    </div>
  );
};

export default UsersTableComponent;
